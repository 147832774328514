@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Arsenal:wght@400;700&family=Varela+Round&display=swap');

* {
  font-family: 'Varela Round', sans-serif;
  font-weight: normal;
  font-style: normal;
}

.container.ua strong {
  font-weight: 700;
}

.container.ua * {
  font-family: 'Arsenal', sans-serif;
  font-weight: 400;
}

.container.ua button {
  font-family: 'Arsenal', sans-serif;
  font-style: normal;
  font-weight: 400;
}

.container.ua h2 {
  font-family: 'Arsenal', sans-serif;
  font-style: normal;
  font-weight: 700;
}

.container.ua header a,
.container.ua footer a {
  font-family: 'Arsenal', sans-serif;
  font-weight: 400;
}

#eng {
  cursor: pointer;
}

p {
  margin: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  text-align: left;
  justify-content: center;
  align-items: center;
  margin: auto;
  overflow-x: hidden;
}

html {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  background-color: white !important;
  width: 100%;
}

.logo {
  height: 60px;
  width: auto;

}



.makeStyles-languageSelect-11:before {
  border: none;
}

.makeStyles-languageSelect-11 {
  /* color: black; */
  border: 3px solid #2A4E63 !important;
  padding: 8px 30px !important;
  border-radius: 60px !important;
}

.css-eg0mwd-MuiSlider-thumb::after,
.css-7drnjp::after {
  position: absolute;
  content: "";
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('./images/selector.png') !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 600px) {
  .css-eg0mwd-MuiSlider-thumb::after {
    height: 20px !important;
    width: 20px !important;
  }
}


.css-14pt78w-MuiSlider-rail {
  display: block;
  position: absolute;
  border-radius: inherit;
  background-color: transparent;
  opacity: 0.38;
  width: 100%;
  height: inherit;
  padding: 2px;
  border: 1px solid black;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}


.css-1gv0vcd-MuiSlider-track,
.css-1t2bqnt {
  display: block;
  position: absolute;
  background-color: #133346 !important;
  -webkit-transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, width 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, width 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: inherit;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@layer utilities {
  .rounded-72 {
    border-radius: 72px;
  }

  .rounded-48 {
    border-radius: 48px;
  }

  .rounded-24 {
    border-radius: 24px;
  }
}

.container.en header a,
.container.en footer a {
  font-size: 18px;
  font-style: normal;
  line-height: 18px;
  /* 100% */
  text-decoration: none;
}

.sepia-60 {
  filter: grayscale(1);
}

ol {
  list-style: decimal;
}
